<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
    width="650px"
  />
</template>

<script>
import { resetRouter } from '@/router'

export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:原密码
        {
          label: this.$t('personalInfo.oldPassword'),
          prop: 'oldPassword',
          rules: {
            noNull: true,
          },
          tagProps: {
            showPassword: true,
          },
        },
        // lang:新密码
        {
          label: this.$t('personalInfo.password'),
          prop: 'password',
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
        // lang:确认新密码
        {
          label: this.$t('personalInfo.confirmPassword'),
          prop: 'confirmPassword',
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
      ],
    }
  },

  methods: {
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const { oldPassword, password, confirmPassword } = this.formData
      if (password !== confirmPassword) {
        this.$element.showMsg(this.$t('personalInfo.inconformity'), 'warning')
        return
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorChangePassword,
        data: {
          oldPassword,
          password,
        },
      }).then(() => {
        // lang:密码修改成功，请重新登录
        this.$element
          .showAlert({
            content: this.$t('personalInfo.passwordEditSuc'),
          })
          .then(() => {
            resetRouter()
            // 清除本地缓存数据
            this.$store.dispatch('clearSessionInfo').then(() => {
              this.$router.replace({ path: '/login' })
            })
          })
      })
    },
  },
}
</script>
